
::v-deep p {
    margin: 0;
}
.paper-detail {
    display: flex;
    flex-direction: column;
    height: 1%;
    flex: 1;
    .exerciseDetail-main {
        height: 1%;
        flex: 1;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .headline-item {
        margin-top: 40px;
        &:first-child {
            margin-top: 0;
        }
        .headlineType-title {
            padding-left: 40px;
            margin-bottom: 20px;
            .title {
                font-size: 18px;
                font-weight: bold;
            }
            .subtitle {
                margin-left: 10px;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
    .subject-item {
        font-size: 16px;
        margin-bottom: 20px;
        .question-title {
            background: #f7f7f7;
            padding: 15px 40px;
            .type {
                font-weight: 500;
            }
            .title {
                ::v-deep p {
                    display: inline;
                }
                ::v-deep img {
                    max-width: 300px !important;
                    max-height: 300px !important;
                }
            }
            .score {
                font-weight: 500;
            }
        }
        .answer-box {
            .question-answer {
                padding: 0 40px;
                &.el-checkbox-group,
                &.el-radio-group {
                    display: flex;
                    flex-wrap: wrap;
                }
                .answer-item {
                    margin-top: 20px;
                    font-weight: 400;
                    color: #333;
                    &.el-radio {
                        display: block;
                        width: 100%;
                        white-space: normal;
                        line-height: 1.5;
                        margin-right: 0;
                        ::v-deep .el-radio__label {
                            font-size: 16px;
                        }
                    }
                    ::v-deep .el-radio__input {
                        white-space: normal;
                        &.is-checked {
                            & + .el-radio__label {
                                color: #333;
                            }
                            .el-radio__inner {
                                border-color: #2dc079;
                                /*background: #2DC079;*/
                                background: transparent;
                                &:after {
                                    width: 5px;
                                    height: 5px;
                                    background-color: #2dc079;
                                }
                            }
                        }
                        .el-radio__inner {
                            &:hover {
                                border-color: #2dc079;
                            }
                        }
                    }
                    &.el-checkbox {
                        white-space: normal;
                        line-height: 1.5;
                        display: block;
                        width: 100%;
                        margin-right: 0;
                        ::v-deep .el-checkbox__label {
                            font-size: 16px;
                        }
                    }
                    ::v-deep .el-checkbox__input {
                        white-space: normal;
                        .el-checkbox__label {
                            /*font-size: 16px;*/
                        }
                        &.is-checked {
                            & + .el-checkbox__label {
                                color: #333;
                            }
                            .el-checkbox__inner {
                                background-color: #2dc079;
                                border-color: #2dc079;
                            }
                        }
                        &.is-indeterminate {
                            .el-checkbox__inner {
                                background-color: #2dc079;
                                border-color: #2dc079;
                            }
                        }
                        .el-checkbox__inner {
                            &:hover {
                                border-color: #2dc079;
                            }
                        }
                    }
                }
                .answer-content {
                    width: 1%;
                    flex: 1;
                }
            }
        }
    }
}

/*单选框组 样式覆盖*/
.radioGroup-purple {
    margin: 0 40px 30px;
    ::v-deep .el-radio-button {
        margin-left: 16px;
        .el-radio-button__inner {
            color: #333;
            border: 1px solid #bfbfbf;
            border-radius: 4px;
            padding: 10px 0;
            width: 64px;
            font-weight: normal;
            &:hover {
                color: #2dc079;
            }
        }
        &:first-child {
            margin-left: 0;
        }
        &:first-child,
        &:last-child {
            .el-radio-button__inner {
                border-radius: 0;
            }
        }
        .el-radio-button__orig-radio:checked + .el-radio-button__inner {
            color: #fff;
            background-color: #2dc079;
            border-color: #2dc079;
            box-shadow: -1px 0 0 0 #2dc079;
            border-radius: 4px;
        }
    }
}
